.customHandleH {
  cursor: ns-resize;
  width: 100px;
  height: 10px;
  margin: 0px -5px;
  background: rgb(39, 40, 41);
  border: 2px solid rgb(71, 71, 71);
  border-radius: 2px;
  text-align: center;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center ;
  display: none;
}
.customHandleV {
  cursor: ew-resize;
  width: 10px;
  height: 100px;
  margin: -5px 0px;
  background: rgb(39, 40, 41);
  border: 2px solid rgb(71, 71, 71);
  border-radius: 2px;
  text-align: center;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center ;
  display:none;
}